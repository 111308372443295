import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

// components
import { useQuery } from "react-query";
import dayjs from "dayjs";
import { getSubscriptions } from "../../../helpers/api/subscription";
import Table from "../../../components/Table/Table";
import { startCase } from "lodash";

const SubscriptionList = () => {
  const columns = [
    {
      Header: "Name",
      accessor: "product.name",
      sort: true,
    },
    {
      Header: "Status",
      accessor: "status",
      sort: true,
      Cell: ({ row }: { row: any }) => startCase(row.original.status),
    },
    {
      Header: "Price",
      accessor: "product.price_amount",
      sort: true,
      Cell: ({ row }: { row: any }) => (
        <>${row.original.product.price_amount}</>
      ),
    },
    {
      Header: "Subscription Date",
      accessor: "created_at",
      sort: true,
      Cell: ({ row }: { row: any }) =>
        dayjs(row.original?.created_at).format("DD MMM, YYYY"),
    },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   sort: false,
    //   Cell: ({ row }: { row: any }) => (
    //     <>
    //       {/* <Link
    //         to="#"
    //         className="action-icon"
    //         onClick={() => {
    //           setSelectedEvent(row.original);
    //         }}
    //       >
    //         <i className="mdi mdi-square-edit-outline"></i>
    //       </Link> */}
    //       {/* <Link to="#" className="action-icon">
    //         <i className="mdi mdi-delete"></i>
    //       </Link> */}
    //     </>
    //   ),
    // },
  ];

  const { data: events } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: () => getSubscriptions(),
  });

  return (
    <>
      <Card>
        <Card.Body>
          <Row className="justify-content-between">
            <Col>
              <Link
                className="btn btn-primary waves-effect waves-light mb-2"
                to={`/apps/subscription/subscribe`}
              >
                Add Subscription
              </Link>
            </Col>
            <Col>
            </Col>
          </Row>
          <Table
            columns={columns}
            data={events?.data?.data || []}
            pageSize={10}
            isSortable={true}
            pagination={true}
            isSearchable={true}
            tableClass="table-nowrap table-hover"
            searchBoxClass="my-2"
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default SubscriptionList;

import React from "react";
import { Route, Navigate, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import MyProfile from "../pages/settings/MyProfile";
import SubscriptionList from "../pages/apps/Subscription/SubscriptionList";
import Subscribe from "../pages/apps/Subscription/Subscribe";
import Success from "../pages/apps/Subscription/success";
import Failure from "../pages/apps/Subscription/failure";
// import Root from './Root';

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const SignInSignUp = React.lazy(() => import("../pages/auth/SignInSignUp"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));
const Discovery = React.lazy(() => import("../pages/apps/Discovery/Discovery"));

// auth2
const Login2 = React.lazy(() => import("../pages/auth2/Login2"));
const Logout2 = React.lazy(() => import("../pages/auth2/Logout2"));
const Register2 = React.lazy(() => import("../pages/auth2/Register2"));
const Confirm2 = React.lazy(() => import("../pages/auth2/Confirm2"));
const ForgetPassword2 = React.lazy(
  () => import("../pages/auth2/ForgetPassword2")
);
const LockScreen2 = React.lazy(() => import("../pages/auth2/LockScreen2"));
const SignInSignUp2 = React.lazy(() => import("../pages/auth2/SignInSignUp2"));

// dashboard
const Dashboard1 = React.lazy(
  () => import("../pages/dashboard/Dashboard1/index")
);

// apps
const CalendarApp = React.lazy(() => import("../pages/apps/Calendar/"));
const Locations = React.lazy(() => import("../pages/apps/Locations"));
const LocationDetails = React.lazy(
  () => import("../pages/apps/Locations/Detail")
);

const LocationDetailsPublic = React.lazy(
  () => import("../pages/apps/Discovery/Detail")
);
const LocationForm = React.lazy(
  () => import("../pages/apps/Locations/LocationForm")
);

const FoodTrucks = React.lazy(() => import("../pages/apps/FoodTrucks/"));
const FoodTruckDetail = React.lazy(
  () => import("../pages/apps/FoodTrucks/Detail/")
);
const FoodTruckForm = React.lazy(
  () => import("../pages/apps/FoodTrucks/FoodTruckForm")
);

// - crm pages
const Events = React.lazy(() => import("../pages/apps/CRM/Events/index"));
const CRMCustomers = React.lazy(
  () => import("../pages/apps/CRM/Customers/index")
);
// - email
// -contacts
const ContactsList = React.lazy(() => import("../pages/apps/Contacts/List/"));
const ContactsProfile = React.lazy(
  () => import("../pages/apps/Contacts/Profile/")
);
// -tickets
const TicketsList = React.lazy(() => import("../pages/apps/Tickets/List/"));
const TicketsDetails = React.lazy(
  () => import("../pages/apps/Tickets/Details/")
);
// - file
const FileManager = React.lazy(() => import("../pages/apps/FileManager"));

// extra pages
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error404Two = React.lazy(() => import("../pages/error/Error404Two"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
const Error500Two = React.lazy(() => import("../pages/error/Error500Two"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/dashboard-root",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/",
      name: "Root",
      element: <Navigate to="/dashboard" />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard",
      name: "Dashboard 1",
      element: <Dashboard1 />,
      route: PrivateRoute,
    },
  ],
};

const calendarAppRoutes: RoutesProps = {
  path: "/apps/calendar",
  name: "Calendar",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "calendar",
  element: <CalendarApp />,
  header: "Apps",
};

const crmAppRoutes = {
  path: "/apps/crm",
  name: "CRM",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "users",
  children: [
    {
      path: "/location/my-schedules",
      name: "Contacts",
      element: <Events />,
      route: PrivateRoute,
    },
    {
      path: "/truck/booked-events",
      name: "Customers",
      element: <CRMCustomers />,
      route: PrivateRoute,
    },
  ],
};

const projectAppRoutes = {
  path: "/apps/locations",
  name: "Locations",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-briefcase",

  children: [
    {
      path: "/apps/locations/list",
      name: "List",
      element: <Locations />,
      route: PrivateRoute,
    },
    {
      path: "/apps/locations/:id/details",
      name: "Detail",
      element: <LocationDetails />,
      route: PrivateRoute,
    },
    {
      path: "/apps/locations/create",
      name: "Create Project",
      element: <LocationForm />,
      route: PrivateRoute,
    },
    {
      path: "/apps/locations/:id/edit",
      name: "Create Project",
      element: <LocationForm />,
      route: PrivateRoute,
    },
    {
      path: "/apps/foodtrucks/list",
      name: "List",
      element: <FoodTrucks />,
      route: PrivateRoute,
    },
    {
      path: "/apps/foodtrucks/:id/details",
      name: "Detail",
      element: <FoodTruckDetail />,
      route: PrivateRoute,
    },
    {
      path: "/apps/foodtrucks/create",
      name: "Create Food Truck",
      element: <FoodTruckForm />,
      route: PrivateRoute,
    },
    {
      path: "/apps/foodtrucks/:id/edit",
      name: "Create Food Truck",
      element: <FoodTruckForm />,
      route: PrivateRoute,
    },
    {
      path: "/apps/subscription/my-subscriptions",
      name: "My Subscriptions",
      element: <SubscriptionList />,
      route: PrivateRoute,
    },
    {
      path: "/apps/subscription/subscribe",
      name: "My Subscriptions",
      element: <Subscribe />,
      route: PrivateRoute,
    },
    {
      path: "/checkout/success/:sessionId",
      name: "Subscription Success",
      element: <Success />,
      route: PrivateRoute,
    },
    {
      path: "/checkout/failure/:sessionId",
      name: "Subscription Failure",
      element: <Failure />,
      route: PrivateRoute,
    },
  ],
};

const contactsRoutes = {
  path: "/apps/contacts",
  name: "Contacts",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "book",
  children: [
    {
      path: "/apps/contacts/list",
      name: "Task List",
      element: <ContactsList />,
      route: PrivateRoute,
    },
    {
      path: "/apps/contacts/profile",
      name: "Profile",
      element: <ContactsProfile />,
      route: PrivateRoute,
    },
  ],
};

const ticketsRoutes = {
  path: "/apps/tickets",
  name: "Tickets",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "aperture",
  children: [
    {
      path: "/apps/tickets/list",
      name: "List",
      element: <TicketsList />,
      route: PrivateRoute,
    },
  ],
};

const fileAppRoutes = {
  path: "/apps/file-manager",
  name: "File Manager",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "folder-plus",
  element: <FileManager />,
};

const appRoutes = [
  calendarAppRoutes,
  crmAppRoutes,
  projectAppRoutes,
  contactsRoutes,
  ticketsRoutes,
  fileAppRoutes,
];

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/auth/signin-signup",
    name: "SignIn-SignUp",
    element: <SignInSignUp />,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
  {
    path: "/auth/login2",
    name: "Login2",
    element: <Login2 />,
    route: Route,
  },
  {
    path: "/auth/logout2",
    name: "Logout2",
    element: <Logout2 />,
    route: Route,
  },
  {
    path: "/auth/register2",
    name: "Register2",
    element: <Register2 />,
    route: Route,
  },
  {
    path: "/auth/confirm2",
    name: "Confirm2",
    element: <Confirm2 />,
    route: Route,
  },
  {
    path: "/auth/forget-password2",
    name: "Forget Password2",
    element: <ForgetPassword2 />,
    route: Route,
  },
  {
    path: "/auth/signin-signup2",
    name: "SignIn-SignUp2",
    element: <SignInSignUp2 />,
    route: Route,
  },
  {
    path: "/auth/lock-screen2",
    name: "Lock Screen2",
    element: <LockScreen2 />,
    route: Route,
  },
];

// Settings Route
const settingsRoute = [
  {
    path: "/settings/my-account",
    name: "My Profile",
    element: <MyProfile />,
    route: Route,
  },
];

// Landing Route
const landingRoute = [
  {
    path: "/",
    name: "Discovery Page",
    element: <Discovery />,
    route: Route,
  },
  {
    path: "/locations/:id",
    name: "Detail",
    element: <LocationDetailsPublic />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes = [
  {
    path: "/error-404",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/error-404-two",
    name: "Error - 404 Two",
    element: <Error404Two />,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
  {
    path: "/error-500-two",
    name: "Error - 500 Two",
    element: <Error500Two />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [dashboardRoutes, ...appRoutes, ...settingsRoute];
const publicRoutes = [...authRoutes, ...otherPublicRoutes, ...landingRoute];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};

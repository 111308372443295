import { APICore } from "./apiCore";

const api = new APICore();

export function updateProfile(params: any) {
  const endpoint = "/accounts/user/profile/";
  return api.updatePatch(`${endpoint}`, params);
}

export function updatePassword(params: any) {
  const endpoint = "/accounts/user/password-change/";
  return api.create(`${endpoint}`, params);
}

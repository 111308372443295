import React from "react";
import { Card, Button } from "react-bootstrap";

// components
import PageTitle from "../../../components/Layout/PageTitle";
import { QueryClient, useMutation, useQuery } from "react-query";
import {
  createStripeSession,
  getAllProducts,
} from "../../../helpers/api/subscription";
import { Subscription } from "./types";
import { toast } from "react-toastify";

const Subscribe = () => {
  const queryClient = new QueryClient();

  const { data: products } = useQuery({
    queryKey: ["products"],
    queryFn: () => getAllProducts(),
    staleTime: Infinity,
  });

  const { mutateAsync: createStripeSessionAsync, isLoading } = useMutation({
    mutationFn: createStripeSession,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["foodTruckDetails"] });
    },
  });

  const subscribe = async (data: any) => {
    try {
      const res = await createStripeSessionAsync({
        stripe_price_id: data,
      });
      window.location.href = res.data.data.url;
    } catch (error) {
      toast.error(error as string);
      console.error("Error: ", error);
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Food Trucks", path: "/apps/foodtrucks/create" },
          {
            label: "Create Food Truck",
            path: "/apps/foodtrucks/create",
            active: true,
          },
        ]}
        title={"Create Food Truck"}
      />

      <div className="d-flex justify-content-center flex-wrap mt-4">
        {products?.data?.data?.map((subscription: Subscription) => (
          <div
            key={subscription.product_id}
            className="col-md-4 mb-3 text-center"
          >
            <Card>
              <Card.Body>
                <Card.Title>{subscription.name}</Card.Title>
                <Card.Text>{subscription.description}</Card.Text>
                <Card.Text>Price: ${subscription.price_amount}</Card.Text>
                <Button
                  variant="primary"
                  disabled={isLoading}
                  onClick={() => subscribe(subscription?.price_id)}
                >
                  Subscribe Now
                </Button>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </>
  );
};

export default Subscribe;

import { APICore } from "./apiCore";

const api = new APICore();

export function getEvents() {
  const endpoint = "/properties/user/events/";
  return api.get(`${endpoint}`, {});
}

export function getEventDetails(params: any) {
  const endpoint = `/properties/user/events/${params.id}/`;
  return api.get(`${endpoint}`, {});
}

export function createEvent(params: any) {
  const endpoint = "/properties/user/events/";
  return api.create(`${endpoint}`, params);
}

export function bookEvent(params: any) {
  const endpoint = "/bookings/user/bookings/";
  return api.create(`${endpoint}`, params);
}
export function cancelEvent(id: string | number | undefined) {
  const endpoint = `/bookings/user/cancel-bookings/${id}/`;
  return api.create(`${endpoint}`, {});
}

export function getAllBookings() {
  const endpoint = "/bookings/user/bookings/";
  return api.get(`${endpoint}`, {});
}

export function getBookingDetails(params: {
  id: number | string | null;
  date: string;
}) {
  if (!params.id) return null;
  const endpoint = `/bookings/user/event-bookings/${params.id}/`;
  return api.get(`${endpoint}`, { date: params.date });
}

export function updateEvent(params: any) {
  const endpoint = `/properties/user/events/${params.id}/`;
  return api.updatePatch(`${endpoint}`, params);
}

import { APICore } from "./apiCore";

const api = new APICore();

export function getLocations() {
  const endpoint = "/properties/user/locations/";
  return api.get(`${endpoint}`, {});
}

export function getAllLocations(data: any) {
  console.log(data);
  const endpoint = "/properties/public/locations/";
  return api.get(`${endpoint}`, data);
}

export function getLocationDetails(params: any) {
  const endpoint = `/properties/user/locations/${params.id}/`;
  return api.get(`${endpoint}`, {});
}
export function getLocationDetailsPublic(params: any) {
  const endpoint = `/properties/public/locations/${params.id}/`;
  return api.get(`${endpoint}`, {});
}

export function createLocation(params: any) {
  const endpoint = "/properties/user/locations/";
  return api.create(`${endpoint}`, params);
}

export function updateLocation(params: any) {
  const endpoint = `/properties/user/locations/${params.id}/`;
  return api.updatePatch(`${endpoint}`, params);
}

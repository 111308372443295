import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthLayout from "../../auth/AuthLayout";
import { getProfile } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { authApiResponseSuccess } from "../../../redux/actions";
import { AuthActionTypes } from "../../../redux/auth/constants";
import { AUTH_SESSION_KEY } from "../../../helpers/api/apiCore";
import { RootState } from "../../../redux/store";

// components
const SignIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.2 130.2"
    >
      {" "}
      <circle
        className="path circle"
        fill="none"
        stroke="#4bd396"
        strokeWidth="6"
        strokeMiterlimit="10"
        cx="65.1"
        cy="65.1"
        r="62.1"
      />{" "}
      <polyline
        className="path check"
        fill="none"
        stroke="#4bd396"
        strokeWidth="6"
        strokeLinecap="round"
        strokeMiterlimit="10"
        points="100.2,40.2 51.5,88.8 29.8,67.5 "
      />{" "}
    </svg>
  );
};

/* bottom link */
const BottomLink = () => {
  const { t } = useTranslation();
  return (
    <Row className="mt-3">
      <Col className="text-center">
        <p className="text-white-50">
          {t("Back to")}{" "}
          <Link
            to={"/apps/subscription/my-subscriptions"}
            className="text-white ms-1"
          >
            <b>{t("Subscriptions")}</b>
          </Link>
        </p>
      </Col>
    </Row>
  );
};

const Success = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => ({
    user: state.Auth.user,
  }));

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profile = await getProfile();
        if (!profile.data.success) throw profile.data;
        dispatch(
          authApiResponseSuccess(AuthActionTypes.LOGIN_USER, {
            ...profile.data.data,
            token: user.token,
          })
        );
        localStorage.setItem(
          AUTH_SESSION_KEY,
          JSON.stringify({
            ...profile.data.data,
            token: user.token,
          })
        );
      } catch (error: any) {
        console.log(error);
      }
    };
    fetchProfile();
  }, [dispatch, user.token]);

  return (
    <>
      <AuthLayout bottomLinks={<BottomLink />}>
        <div className="text-center">
          <div className="mt-4">
            <div className="logout-checkmark">
              <SignIcon />
            </div>
          </div>

          <h3>{t("Subscription Successful!")}</h3>

          <p className="text-muted">
            {" "}
            {t(
              "You are now successfully subscribed. Thank you for subscribing."
            )}{" "}
          </p>
        </div>
      </AuthLayout>
    </>
  );
};

export default Success;

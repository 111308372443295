import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// apicore
import { APICore, setAuthorization } from "../../helpers/api/apiCore";

// helpers
import {
  forgotPassword as forgotPasswordApi,
  login as loginApi,
  getProfile as getProfileApi,
} from "../../helpers/";

// actions
import { authApiResponseSuccess, authApiResponseError } from "./actions";

// constants
import { AuthActionTypes } from "./constants";

interface UserData {
  payload: {
    username: string;
    password: string;
    fullname: string;
    email: string;
  };
  type: string;
}

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({
  payload: { username, password },
  type,
}: UserData): SagaIterator {
  try {
    const response = yield call(loginApi, { username, password });
    if (!response?.data?.success) throw response?.data;
    setAuthorization(response.data.data.access_token);
    const profile = yield call(getProfileApi);
    if (!profile.data.success) throw profile.data;
    api.setLoggedInUser({
      ...profile.data.data,
      token: response.data.data.access_token,
    });
    yield put(
      authApiResponseSuccess(AuthActionTypes.LOGIN_USER, {
        ...profile.data.data,
        token: response.data.data.access_token,
      })
    );
  } catch (error: any) {
    console.log("Here", error);
    yield put(
      authApiResponseError(
        AuthActionTypes.LOGIN_USER,
        "Invalid Username or Password"
      )
    );
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
    api.setLoggedInUser(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* forgotPassword({ payload: { username } }: UserData): SagaIterator {
  try {
    const response = yield call(forgotPasswordApi, { username });
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data)
    );
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}

export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchForgotPassword),
  ]);
}

export default authSaga;

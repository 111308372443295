import { APICore } from "./apiCore";

const api = new APICore();

export function getSubscriptions() {
  const endpoint = "/subscriptions/user/subscriptions/";
  return api.get(`${endpoint}`, { page_size: 0 });
}
export function getAllProducts() {
  const endpoint = "/subscriptions/user/products/";
  return api.get(`${endpoint}`, { page_size: 0 });
}

export function createStripeSession(data: any) {
  const endpoint = `/subscriptions/user/create-checkout-session/`;
  return api.create(endpoint, data);
}

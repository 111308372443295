import { APICore } from "./apiCore";

const api = new APICore();

export function getFoodTrucks() {
  const endpoint = "/properties/user/food-trucks/";
  return api.get(`${endpoint}`, {});
}

export function getFoodTruckDetails(params: any) {
  const endpoint = `/properties/user/food-trucks/${params.id}/`;
  return api.get(`${endpoint}`, {});
}

export function createFoodTruck(params: any) {
  const endpoint = "/properties/user/food-trucks/";
  return api.create(`${endpoint}`, params);
}

export function updateFoodTruck(params: any) {
  const endpoint = `/properties/user/food-trucks/${params.id}/`;
  return api.updatePatch(`${endpoint}`, params);
}
